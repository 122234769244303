<template>
  <v-container
      id="extended-tables"
      fluid
      tag="section"
  >
    <v-row no-gutters>
      <v-col cols="12" md="12">

        <base-material-card
            color="success"
            icon="mdi-server"
            :title="baseCardTitle"
            inline
        >
          <v-row>
            <v-col cols="10"></v-col>
            <v-col cols="2" class="text-right">
              <v-btn small right color="info" class="mr-0" @click="goToPage({route: 'Resources'})">
                <v-icon small class="mr-1">
                  mdi-arrow-left
                </v-icon>
                Back
              </v-btn>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" md="12">
              <template v-if="loading">
                <v-progress-linear
                    indeterminate
                    color="primary"
                ></v-progress-linear>
                <v-skeleton-loader loading class="mx-auto" type="card"></v-skeleton-loader>
              </template>
              <template v-else>
                <v-row>
                  <v-col cols="6" xs="12" md="6">
                    <v-text-field
                        label="Name *"
                        :rules="[() => !!cluster.name || 'This field is required!']"
                        v-model="cluster.name"
                        counter
                        required
                    />
                  </v-col>
                  <v-col cols="6" xs="12" md="6">
                    <v-text-field
                        :disabled="!fieldAccess.category"
                        label="Category *"
                        :rules="[() => !!cluster.category || 'This field is required!']"
                        v-model="cluster.category"
                        counter
                        required
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-switch
                        :disabled="!fieldAccess.locked"
                        label="Locked *"
                        v-model="cluster.locked"
                        inset
                        required
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <h3>Servers</h3>
                    <v-divider class="mt-2 mb-3"></v-divider>
                    <v-btn small color="secondary" @click="cluster.config.servers.push({host: '', port: ''});">
                      <v-icon small color="white" class="mr-1">mdi-plus</v-icon>
                      Add New Server
                    </v-btn>

                    <v-btn small color="success" @click="testConnection()"
                           v-if="canAccess({route: '/environments/:env/data-clusters/connect', method: 'post'})"
                           v-show="cluster.config.servers.length > 0">
                      Test Connection
                    </v-btn>

                  </v-col>
                </v-row>
                <template v-if="cluster.config && cluster.config.servers && cluster.config.servers.length > 0">
                  <v-row v-for="(oneServer, i) in cluster.config.servers" :key="i">
                    <v-col cols="8" xs="10" md="8">
                      <v-text-field
                          label="Host Address *"
                          :rules="[() => !!oneServer.host || 'This field is required!']"
                          v-model="oneServer.host"
                          counter
                          required
                      />
                    </v-col>
                    <v-col cols="2" xs="1" md="2">
                      <v-text-field
                          type="number"
                          label="Port *"
                          :rules="[() => !!oneServer.port || 'This field is required!']"
                          v-model="oneServer.port"
                          counter
                          required
                      />
                    </v-col>
                    <v-col cols="1" class="text-center align-center">
                      <div v-show="canAccess({route: '/environments/:env/data-clusters/connect', method: 'post'})"
                           style="padding-top: 20px;">

                        <v-tooltip v-if="oneServer.up" bottom>
                          <template v-slot:activator="{ attrs, on }">
                            <v-icon v-bind="attrs" v-on="on" class="mr-1" medium color="green">
                              mdi-link
                            </v-icon>
                          </template>
                          <span>Connected</span>
                        </v-tooltip>
                        <v-tooltip v-else bottom>
                          <template v-slot:activator="{ attrs, on }">
                            <v-icon v-bind="attrs" v-on="on" class="mr-1" medium color="red">
                              mdi-link-off
                            </v-icon>
                          </template>
                          <span>Not Connected</span>
                        </v-tooltip>

                      </div>

                    </v-col>
                    <v-col cols="1" class="text-right align-center">
                      <v-btn
                          class="mt-5"
                          small
                          color="error"
                          @click="cluster.config.servers.splice(i, 1)"
                      >
                        <v-icon small color="white">
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
                <template v-else>
                  <v-row>
                    <v-col cols="12">
                      <v-alert type="error" text dense border="left">
                        At least 1 server should be configured for this cluster!
                      </v-alert>
                    </v-col>
                  </v-row>
                </template>

                <v-row>
                  <v-col cols="12">
                    <h3>Credentials</h3>
                    <v-divider class="mt-2 mb-1"></v-divider>
                  </v-col>
                  <v-col cols="6" xs="12" md="6">
                    <v-text-field :disabled="!fieldAccess.config"
                                  label="Username"
                                  v-model="cluster.config.credentials.username"
                                  counter
                                  required
                    />
                  </v-col>
                  <v-col cols="6" xs="12" md="6">
                    <v-text-field :disabled="!fieldAccess.config"
                                  type="password"
                                  label="Password"
                                  v-model="cluster.config.credentials.password"
                                  counter
                                  required
                    />
                  </v-col>
                  <v-col cols="6" xs="12" md="6">
                    <v-col cols="12">
                      <h3>Extra Optional Configuration</h3>
                      <v-divider class="mt-2 mb-1"></v-divider>
                    </v-col>
                    <v-jsoneditor v-if="fieldAccess.config"
                                  class="py-2"
                                  v-model="cluster.config.URLParam" :plus="true" :options="{mode: 'code'}"
                                  height="300px"
                    />
                    <json-viewer v-else
                                 class="my-2 font11"
                                 :value="cluster.config.URLParam"
                                 :expand-depth="2"
                                 theme="my-awesome-json-theme"
                                 boxed
                                 expanded
                                 height="300px"
                    ></json-viewer>
                  </v-col>
                  <v-col cols="6" xs="12" md="6">
                    <v-col cols="12">
                      <h3>Optional Streaming Configuration</h3>
                      <v-divider class="mt-2 mb-1"></v-divider>
                    </v-col>
                    <v-jsoneditor v-if="fieldAccess.config"
                                  class="py-2"
                                  v-model="cluster.config.streaming"
                                  :plus="true" :options="{mode: 'code'}" height="300px"
                    />
                    <json-viewer v-else
                                 class="my-2 font11"
                                 :value="cluster.config.streaming"
                                 :expand-depth="2"
                                 theme="my-awesome-json-theme"
                                 boxed
                                 expanded
                                 height="300px"
                    ></json-viewer>

                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" class="text-right pr-0 mr-0">
                    <v-btn
                        color="success"
                        v-if="canAccess({route: '/environments/:env/data-clusters', method: 'put'}) || canAccess({route: '/environments/:env/data-clusters/:id', method: 'patch'})"
                        @click="saveCluster">
                      <v-icon class="mr-1">mdi-content-save</v-icon>
                      {{ this.id && this.id.trim() !== '' ? 'Save' : 'Create' }}
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import globalMixins from "@/mixins/globalMixins";
import fieldsMixins from "./components/fieldAccess";
import VJsoneditor from "v-jsoneditor";
import JsonViewer from 'vue-json-viewer';

export default {
  name: 'AddEditResources',

  mixins: [globalMixins, fieldsMixins],

  components: {
    VJsoneditor, JsonViewer
  },

  props: {
    envSelected: {
      type: Object
    },
    id: {
      type: String
    }
  },

  data() {
    return {
      fieldAccess: {},
      loading: false,
      cluster: {
        name: '',
        category: '',
        locked: false,
        type: 'database',
        config: {
          servers: [],
          credentials: {}
        }
      }
    }
  },

  computed: {
    baseCardTitle() {
      if (this.id && this.cluster.name) {
        return 'Updating Cluster ' + this.cluster.name;
      } else {
        return 'Creating New Cluster';
      }
    }
  },

  created() {
    const _self = this;
    _self.initialize();
  },

  methods: {
    initialize() {
      const _self = this;
      this.loading = true;
      if (this.id && this.id.trim() !== '') {
        _self.fieldAccess = _self.resourceUpdateAccess();
        // in case of edit
        let apiOptions = {
          url: `/consoleapi/environments/${this.envSelected.value}/data-clusters/${this.id}`,
          method: "get",
        };
        this.getSendData(apiOptions)
            .then((response) => {
              this.cluster = response.item;
              if (!this.cluster.config.credentials) {
                this.cluster.config.credentials = {};
              }

              //wait a bit for the servers list to finish its rendering
              setTimeout(() => {
                if (this.canAccess({route: '/environments/:env/data-clusters/connect', method: 'post'})) {
                  this.testConnection();
                }
                _self.loading = false;
              }, 150);
            });
      } else {
        _self.fieldAccess = _self.resourceCreateAccess();
        this.cluster.config.servers.push({
          host: '', port: 0, up: false
        });
        setTimeout(() => {
          this.loading = false;
        }, 150);
      }
    },

    checkBasic(cluster) {
      this.clearMessages();
      if (!cluster.name || cluster.name.trim() === '') {
        this.pushMessage({
          type: 'error',
          title: 'Validation Error',
          text: 'Field Name is required!'
        });
        return false;
      }

      if (!cluster.category || cluster.category.trim() === '') {
        this.pushMessage({
          type: 'error',
          title: 'Validation Error',
          text: 'Field Category is required!'
        });
        return false;
      }

      if (!cluster.config.servers || cluster.config.servers.length === 0) {
        this.pushMessage({
          type: 'error',
          title: 'Validation Error',
          text: 'The cluster should have at least 1 server!'
        });
        return false;
      }

      let invalid = false;
      cluster.config.servers.forEach((oneServer) => {
        if (!oneServer.host || oneServer.host.trim() === '') {
          this.pushMessage({
            type: 'error',
            title: 'Validation Error',
            text: 'The cluster has a server entry that has no host value!'
          });
          invalid = true;
        }

        if (!oneServer.port || oneServer.port === 0) {
          this.pushMessage({
            type: 'error',
            title: 'Validation Error',
            text: 'The cluster has a server entry that has no port value!'
          });
          invalid = true;
        }
      });

      if (invalid) {
        return false;
      }

      return true;
    },

    saveCluster() {
      if (!this.checkBasic(this.cluster)) {
        this.scrollToTop();
        return false;
      }

      let payload = this._lodash.cloneDeep(this.cluster);
      payload.config.servers.forEach((server) => {
        delete server.up;
      });

      let apiOptions;
      if (this.id && this.id.trim() !== '') {
        // edit mode
        let patchData = {
          name: payload.name,
          category: payload.category,
          type: payload.type,
          config: payload.config,
          locked: payload.locked || false
        };
        this.filterFields(patchData, this.fieldsAcl.resources.update);

        apiOptions = {
          url: `/consoleapi/environments/${this.envSelected.value}/data-clusters/${this.id}`,
          method: "patch",
          params: patchData
        };
      } else {
        apiOptions = {
          url: `/consoleapi/environments/${this.envSelected.value}/data-clusters`,
          method: "put",
          params: {
            name: payload.name,
            category: payload.category,
            type: payload.type,
            config: payload.config,
            locked: payload.locked || false
          }
        };
      }

      if (apiOptions.params.config.credentials) {
        if (Object.keys(apiOptions.params.config.credentials).length === 0) {
          delete apiOptions.params.config.credentials;
        }
      }

      this.getSendData(apiOptions).then(() => {
        let label = (this.id && this.id.trim() !== '') ? 'updated' : 'created';
        this.pushMessage({
          type: 'success',
          title: `Cluster ${label}`,
          text: `The cluster ${payload.name} has been ${label}!.`
        });
        this.scrollToTop();

        setTimeout(() => {
          if (this.id && this.id.trim() !== '') {
            this.initialize();
          } else {
            this.goToPage({route: 'Resources'});
          }
        }, 2000);
      });
    },

    testConnection() {
      let cluster = this._lodash.cloneDeep(this.cluster);

      cluster.config.servers.forEach((server) => {
        delete server.up;
      });

      let apiOptions;
      apiOptions = {
        url: `/consoleapi/environments/${this.envSelected.value}/data-clusters/connect`,
        method: "post",
        params: {
          servers: cluster.config.servers
        }
      };

      this.getSendData(apiOptions).then((response) => {
        this.cluster.config.servers = response.items;
        setTimeout(() => {
        }, 200);
      });
    }
  }
}
</script>